import React from 'react';
import my from './Data';
import './App.sass';

function App() {
  return (
    <div className="container">

      <section className="section">
        <div className="content is-large">
          <h1 className="has-text-grey">{my.name}<span className="is-hidden-mobile"> | {`Résumé 2020`}</span></h1>
          <p className="has-text-grey">{my.summary}</p>
          {/* <small className="has-text-grey is-hidden-mobile">{my.subsummary}</small> */}
        </div>

        <div className="columns">
          <div className="column">
            <hr/>
            <div className="content">
              <h6 className="has-text-weight-light">Work Experience</h6>
              <ul>
                { my.experience.map(({ company, title }) => <li>{company} <small className="has-text-grey-light">{title}</small></li>) }
              </ul>
            </div>
          </div>
          <div className="column">
            <hr/>
            <div className="content">
              <h6 className="has-text-weight-light">Skills Summary</h6>
              <ul>
                { my.skills.map(skill => <li>{skill}</li>) }
              </ul>
            </div>
          </div>
          <div className="column">
            <hr/>
            <div className="content">
              <h6 className="has-text-weight-light">Interests &amp; Projects</h6>
              <ul>
                { my.projects.map(({ name, url, title }) => <li>
                  { url ? <a href={url}>{name}</a> : name }{' '}
                  <small className="has-text-grey-light">{title}</small>
                </li>) }
              </ul>
            </div>
          </div>
          <div className="column">
            <hr/>
            <div className="content">
              <h6 className="has-text-weight-light">Contact</h6>
              <p>
              For a full version of my r&eacute;sum&eacute;, inquire about
              services or to get in touch, email me at <a href={`mailto:${my.email}`}>{my.email}</a>.
              </p>
              <div className="social-icons">
                <a href="https://linkedin.com/in/luisggomez">
                  <span className="icon is-medium has-text-grey-light">
                    <i className="fab fa-2x fa-linkedin-in"></i>
                  </span>
                </a>
                <a href="https://github.com/lgomez">
                  <span className="icon is-medium has-text-grey-light">
                    <i className="fab fa-2x fa-github"></i>
                  </span>
                </a>{' '}
                <a href="https://twitter.com/luisgo">
                  <span className="icon is-medium has-text-grey-light">
                    <i className="fab fa-2x fa-twitter"></i>
                  </span>
                </a>{' '}
              </div>
            </div>
          </div>
        </div>

        <hr/>
        <small className="has-text-weight-light has-text-grey-light">
          &copy;2020 Trademarks and logos are registered trademarks of their respective companies. All other rights reserved.
        </small>
{/* 
        <hr/>
        <div className="logos">
          { my.logos.map(({ slug, alt }) => <img src={`/img/logos/${slug}@0.75x.png`} alt={alt}/>) }
        </div> */}

      </section>

    </div>
  );
}

export default App;
