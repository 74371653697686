export default {
  name: `Luis G. Gómez`,
  email: `luis@luis.io`,
  summary: `Technical Program Manager, Engineering Director & 
  Solutions Architect with over twenty two years of experience
  developing, executing and managing technology products and
  teams for early-stage startups and global enterprises. 
  Strong focus on people, agile development practices and 
  delivery of cloud-based SaaS products. Bilingual (Engl. & Sp.)
  with experience in the US, Latin America and Europe.`,
  subsummary: `Strong focus on Software Design, Architecture, 
  Development & Delivery as well as API & Integration Program 
  Management, DevOps, Automation, Agile Methodologies, Developer
  Experience and Engineering Team Growth and Mentoring.`,
  taglines: [
    `2020 Résumé`,
    'API Management',
    'Cloud Software Engineer',
    'Product Management',
    'UX Designer',
    'DevOps Engineer',
    'Product Design',
    'Automation Engineer',
    'Microservice Architect',
    'Full-stack Cloud Engineer'
  ],
  skills: [
    `Technical Direction`,
    `Data Science & Analytics`,
    `API Design & Management`,
    `Blockchains & DApps`,
    `DevOps, CI/CD & Automation`,
    `Agile Project Management (Certified)`,
    `Microservice Archs. & Orchestration`,
    `Full-stack Cloud Engineer`,
    `Product Management, UX & UI Design`
  ],
  experience: [
    { company: `Google, LLC`,  title: `TPgM - Core Dev Server` },
    { company: `Cisco Systems, Inc.`,  title: `Architect - Blockchain` },
    { company: `BCG & BCGDV`,  title: `Consultant - Blockchain` },
    { company: `APIM I/O`,  title: `Founder - API Management` },
    { company: `Intel Corp.`,  title: `Sr. Eng. - API Management` },
    { company: `Mashery, Inc.`,  title: `Sr. Eng. - Acq. Intel Corp.` },
    { company: `RentJuice Corp.`,  title: `Founder - Acq. Zillow Corp.` },
    { company: `Qwiki, Inc.`,  title: `Sr. Eng. - Acq. ABC News` },
    { company: `Mixtent, Inc.`,  title: `Consultant - Acq. Facebook` },
    { company: `Buongiorno USA, Inc., IFX, Inc.`,  title: `more...` },
  ],
  projects: [
    { name: `Silicon Valley Blockchain Society`,  url: `http://svbsociety.com/`, title: `member` },
    { name: `Berkeley Blockchain Xelerator`,  url: `https://xcelerator.berkeley.edu`, title: `mentor` },
    { name: `API Management & Engagement`, title: `expertise` },
    { name: `Expert Node JS & Related`, title: `expert` },
    { name: `Python, Pandas & Data Science`, title: `advanced` },
    { name: `Kubernetes & Orchestration`, title: `advanced` },
    { name: `Zero AI & Rainfall`,  url: `https://rainfall.one`, title: `advisor & contributor` },
    { name: `Crypto Asset Capital, Corp.`,  url: `https://ca.capital`, title: `founder` },
    { name: `Low Mango`,  url: `https://lowmango.com/`, title: `investor & advisor` },
    // { name: `kubed`,  url: `https://www.npmjs.com/package/kubed`, title: `owner` },
    // { name: `serviced`,  url: `https://www.npmjs.com/package/serviced`, title: `owner` },
    // { name: `elasticsearch-del…`,  url: `https://www.npmjs.com/package/elasticsearch-deletebyquery`, title: `collaborator` },
  ],
  profiles: [
    { name: `GitHub`,  url: `https://github.com/lgomez` },
    { name: `NPM`,  url: `https://www.npmjs.com/~lgomez` },
    { name: `Stack Overflow`,  url: `http://stackoverflow.com/users/270628/luisgo` },
    { name: `Twitter`,  url: `https://twitter.com/lgomez` },
    { name: `LinkedIn`,  url: `https://linkedin.com/in/luisggomez` },
  ],
  logos: [
    { slug: 'cisco', alt: 'Cisco Systems, Inc.' },
    { slug: 'bcg', alt: 'The Boston Consulting Group' },
    { slug: 'bcgdv', alt: 'BCG Digital Ventures' },
    { slug: 'intel', alt: 'Intel Corp.' },
    { slug: 'mashery', alt: 'Mashery, Inc.' },
    { slug: 'zillow', alt: 'Zillow Corp.' },
    { slug: 'rentjuice', alt: 'RentJuice Corp. (Acq. Zillow)' },
    { slug: 'svbs', alt: 'Silicon Valley Blockchain Society' },
    { slug: 'bbx', alt: 'Berkeley Blockchain Xelerator' },
    { slug: 'node', alt: 'Node JS' },
    { slug: 'python', alt: 'Python' },
    { slug: 'k8s', alt: 'Kubernetes' },
  ]
}
